<template>
    <div class="body">
        <div class="container">
            <div class="section list">
                <!-- <div class="section-header">
                    <strong class="text-ellipsis">{{task.name}}</strong>
                </div> -->
                <div class="section-body with-footer">
                    <div class="section-body-item"
                        v-for="(item, i) in list" :key="i"
                        @click="toDetail(item.id)"
                        >
                         <div class="clear" @click.stop="del(item)">
                             <!-- <van-icon name="delete-o" /> -->
                            <van-icon name="clear" size="22" color="#f98c21" /> 
                         </div>
                        <img :src="item.cover" >
                        <div class="title text-ellipsis">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getUserCollection, updateCoursewareCollection} from '../api/task.js';
    import { checkLogin } from '../utils/common.js';
    export default{
        activated() {
            checkLogin().then(()=> {
                this.getLessons();
            })
            
            this.hideTab();
        },
        data() {
            return{
                list: [],
            }
        },
        methods:{
            getLessons(){
               getUserCollection().then((ret)=>{
                   console.log(ret);
                   this.list = ret.data;
               }) 
            },
            toDetail(id){
                this.$router.push(`/courseware-detail?id=${id}`);
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.remove('tab-hide');
            },
            del(item){
                console.log('del');
                var data = {
                    flag: 0,
                    name: item.name,
                };
                updateCoursewareCollection(item.coursewareid, data).then(()=> {
                    this.getLessons();
                })
            }
        }
    }
</script>

<style scoped>
    @import url('../assets/css/common.css');
    .section-body{
        background-color: #fff;
    }
    .title{
        line-height: 1.8;
    }
    .clear{
        position: absolute;
        right: -3px;
        top: -6px;
        /* z-index: 888; */
    }
</style>